import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getStepTimers(step: string) {
  const timerRegex =
    /((?:\d+\s?(?:-|to)\s?\d+)|(?:\d+))\s?(?:minutes|seconds|min|sec|mins|secs|hour|hours)/gm;
  const timerItems = step.matchAll(timerRegex);
  let timers: {
    unit: "minute" | "second" | "hour" | "minutes" | "seconds" | "hours";
    seconds: number;
    time: number;
  }[] = [];
  for (let timer of timerItems) {
    let match = timer[1];
    if (!match) continue;
    (match.includes(" to ") ? match.split(" to ") : match.split("-")).forEach(
      (match) => {
        let time: {
          unit: "minute" | "second" | "hour" | "minutes" | "seconds" | "hours";
          time: number;
          seconds: number;
        } = {
          unit: Number(match) === 1 ? "second" : "seconds",
          time: Number(match),
          seconds: Number(match),
        };
        if (
          timer[0]
            .replace("min", "minute")
            .replace("mins", "minute")
            .includes("minute")
        ) {
          time.unit = time.time === 1 ? "minute" : "minutes";
          time.seconds = Number(match) * 60;
        }
        if (timer[0].includes("hour")) {
          time.unit = time.time === 1 ? "hour" : "hours";
          time.seconds = Number(match) * 60 * 60;
        }
        timers.push(time);
      }
    );
  }
  return timers;
}
